<template>
    <main class="about">
        <div class="about-main__body">
            <div class="about-main__content">
                <h1 class="about-main__title">Acerca de Bchollos</h1>

                <p class="about-main__text">
                    Bchollos es un sitio web de comunidad que, desde sus inicios en septiembre de 2023, ha
                    tenido un objetivo claro: hacer que nuestros usuarios consigan un ahorro inmediato al
                    utilizar códigos de descuento y ofertas disponibles en nuestra web, lo cual hemos logrado
                    gracias a la transparencia y compromiso que nos caracteriza.
                </p>

                <img
                    class="about-main__img"
                    :src="$assets.illustration.aboutUs.bchollos.bannerAboutUs1"
                    alt="Megadescuentos banner"
                />

                <h2 class="about-main__subtitle">¿Cómo funciona Bchollos?</h2>

                <p class="about-main__text">
                    Bchollos es un sitio web gratuito, en el cual las ofertas y los cupones están disponibles
                    para todo público. Sin embargo, registrarse tiene ciertas ventajas, como acumular monedas
                    por cada una de tus interacciones. A continuación, un resumen de cómo funciona nuestro
                    sitio web:
                </p>

                <ul class="about-main__list">
                    <li class="about-main__list-item">
                        Nuestro sitio está repleto de ofertas y códigos promocionales en diferentes
                        categorías, electrónica, moda, hogar, deportes, salud y más. Cada uno de estos
                        descuentos ha sido publicado por miembros de nuestra comunidad o por nuestros
                        editores.
                    </li>
                    <li class="about-main__list-item">
                        De esta manera, los usuarios pueden aprovechar las ofertas al hacer clic en ellas y
                        ser redirigidos a la web de la tienda. O bien, copiar el código del cupón para
                        aplicarlo en la cesta de compra.
                    </li>
                    <li class="about-main__list-item">
                        Al mismo tiempo, el usuario puede valorar la oferta según su experiencia, comentar,
                        guardarla o compartirla.
                    </li>
                    <li class="about-main__list-item">
                        Si el usuario decide realizar la compra, es probable que nuestro sitio web reciba una
                        pequeña comisión. Esto nos ayuda a financiar el sitio web, para que continúe
                        funcionando.
                    </li>
                </ul>

                <h2 class="about-main__subtitle">¿Cómo ganamos dinero?</h2>

                <p class="about-main__text">
                    Asimismo, en el sitio nos hemos dedicado a crear espacios para que los usuarios puedan
                    compartir sus códigos de referidos de distintas marcas, como Temu, para que consigan
                    beneficios adicionales por cada persona que lo use para registrarse.
                </p>

                <p class="about-main__text">
                    En algunos casos, las ofertas o cupones en Bchollos tienen enlaces de afiliados. De esta
                    manera, si un usuario realiza la compra, el sitio recibe una compensación por ello. Sin
                    embargo, esta comisión no afecta, en lo absoluto, el precio que paga el usuario en dicha
                    transacción. Puedes conocer todos los detalles sobre nuestra
                    <NLink
                        class="about-main__link"
                        to="/blog/general/te-contamos-como-bchollos-genera-ingresos-y-como-lo-mantenemos"
                        >monetización aquí</NLink
                    >.
                </p>

                <p class="about-main__text">
                    Actualmente, colaboramos con empresas como Tradetracker, Awin, Tradedoubler, Webgains,
                    entre otras. Son plataformas reconocidas a nivel mundial por su trayectoria en el campo de
                    marketing de afiliados, siendo clave en el crecimiento de las ventas de muchas empresas en
                    España.
                </p>

                <img
                    class="about-main__img"
                    :src="$assets.illustration.aboutUs.bchollos.bannerAboutUs2"
                    alt="Megadescuentos banner"
                />

                <h2 class="about-main__subtitle">Todas nuestras ofertas y cupones son verificados</h2>

                <p class="about-main__text">
                    Desde nuestros inicios, reconocemos que la satisfacción de nuestros usuarios es la
                    prioridad. Por ello, contamos con un
                    <NLink
                        class="about-main__link"
                        to="/blog/compras-online/por-que-confiar-en-bchollos-y-como-funciona"
                        >proceso de verificación manual</NLink
                    >
                    de todas las ofertas y cupones que se encuentran disponibles en la web. De esta manera,
                    los usuarios pueden ahorrar tiempo al momento de buscar el mejor descuento, ya que el
                    equipo de Bchollos dedica horas a identificar los códigos que realmente funcionan.
                </p>

                <h2 class="about-main__subtitle">¿En qué nos diferenciamos?</h2>

                <p class="about-main__text">
                    En Bchollos estamos comprometidos en ser más que un sitio web de cupones, partiendo desde
                    nuestro objetivo de ayudar a los usuarios a ahorrar dinero, nos enorgullece aportar
                    contenido útil de la siguiente manera:
                </p>

                <ul class="about-main__list">
                    <li class="about-main__list-item">
                        Nuestra comunidad ha convertido nuestro sitio web en un espacio para comparar precios
                        y encontrar la mejor oferta.
                    </li>
                    <li class="about-main__list-item">
                        Todos los días hay oportunidades de descubrir nuevos productos o servicios con
                        descuentos. Al navegar por nuestras distintas categorías, los usuarios pueden
                        beneficiarse de códigos de descuento en marcas que anteriormente no conocían.
                    </li>
                    <li class="about-main__list-item">
                        Al ser una comunidad, nuestros usuarios pueden leer comentarios y reseñas de otros
                        usuarios sobre su experiencia con descuentos, productos o marcas.
                    </li>
                </ul>

                <img
                    class="about-main__img"
                    :src="$assets.illustration.aboutUs.bchollos.bannerAboutUs3"
                    alt="Megadescuentos banner"
                />

                <h2 class="about-main__subtitle">¿Quién dirige Bchollos?</h2>

                <p class="about-main__text">
                    Hurtado Deals es la compañía que desarrolló a Bchollos. Desde sus inicios en el 2013,
                    cuenta con una amplia trayectoria en sitios webs de cupones, descuentos y toda clase de
                    ahorro para compras en línea. Gracias a su experiencia en este mercado, Bchollos se ha
                    consolidado rápidamente como una fuente confiable de cupones para la comunidad española. A
                    continuación, más detalles sobre nuestro equipo:
                </p>

                <section class="about-main__team">
                    <article class="team-card">
                        <div class="team-card__info">
                            <img
                                class="info-img"
                                :src="$assets.illustration.aboutUs.bchollos.aboutPeople1"
                                alt="Megadescuentos banner"
                            />

                            <div class="info-content">
                                <h3 class="info-content__name">Juanca Hurtado</h3>
                                <h3 class="info-content__job">CEO de Hurtado Global Media Group</h3>
                            </div>
                        </div>

                        <p class="team-card__text">
                            Juanca es el director general de Hurtado Deals, él es quien se ha encargado de
                            crear productos digitales relacionados a cupones y descuentos en línea. Y, en su
                            amplia trayectoria, tuvo la visionaria idea de crear una comunidad para
                            ahorradores como lo es Bchollos. Actualmente, su trabajo se centra en estudiar el
                            rendimiento del sitio, con el fin de identificar nuevas oportunidades de mejoras.
                            Por otro lado, es el encargado de establecer nuevas relaciones con marcas y redes
                            de afiliados.
                        </p>
                    </article>

                    <hr class="about-main__team-line" />

                    <article class="team-card">
                        <div class="team-card__info">
                            <img
                                class="info-img"
                                :src="$assets.illustration.aboutUs.bchollos.aboutPeople2"
                                alt="Megadescuentos banner"
                            />

                            <div class="info-content">
                                <h3 class="info-content__name">Kimberly Rojas</h3>
                                <h3 class="info-content__job">Editora de contenidos</h3>
                            </div>
                        </div>

                        <p class="team-card__text">
                            Kimberly es la encargada de moderar las publicaciones de cupones y ofertas de los
                            usuarios en Bchollos. La importancia de su trabajo radica en verificar cada
                            descuento. Además, también se encarga de monitorear todos los canales de las
                            tiendas, con el fin de seguir recopilando ofertas, promociones, códigos de
                            descuento, entre otros aportes a nuestra comunidad.
                        </p>
                    </article>
                </section>
            </div>
        </div>
    </main>
</template>

<script lang="ts" setup>
const Route = useRoute()

const {
    public: { origin, variant },
} = useRuntimeConfig()

const description =
    'Descubre más sobre Bchollos en esta página. Quiénes somos, qué hacemos y por qué lo hacemos.'
const title = 'Acerca de nosotros | Bchollos'

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
</script>

<style lang="postcss">
.about {
    &-main {
        @apply m-0 mx-auto space-x-2 lg:container md:mb-10 md:mt-4 lg:max-w-screen-xl;

        &__body {
            @apply mx-auto my-4 max-w-screen-xl overflow-hidden bg-white lg:container md:rounded-3xl lg:w-3/4;
        }

        &__content {
            @apply px-6 py-4;
        }

        &__title {
            @apply mb-6 mt-4 text-center text-2xl font-semibold;
        }

        &__subtitle {
            @apply mb-4 text-2xl font-semibold;
        }

        &__img {
            @apply mb-6 block w-full object-contain md:mb-8 md:h-[500px];
        }

        &__text {
            @apply mb-6 text-base;
        }

        &__link {
            @apply font-semibold underline;
        }

        &__list {
            @apply mb-6 ml-8 space-y-2;

            &-item {
                @apply list-decimal;
            }

            &-item::marker {
                @apply mr-1 font-semibold;
            }
        }

        &__team {
            @apply mt-8 space-y-8;

            &-line {
                @apply w-full bg-gray-200;
            }

            .team-card {
                @apply flex flex-col gap-2 last:mb-4;

                &__info {
                    @apply flex gap-4;

                    .info-img {
                        @apply h-32 w-32 rounded-3xl object-contain;
                    }

                    .info-content {
                        @apply flex flex-col justify-center;

                        &__name {
                            @apply text-lg font-semibold;
                        }

                        &__job {
                            @apply text-base;
                        }
                    }
                }

                &__text {
                    @apply col-span-5 row-start-4 text-base;
                }
            }
        }
    }
}
</style>
